import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPageList } from "../../store/Action/PageAction";
import { useLocation } from "react-router-dom";
import {
  getOrganisationCountList,
  getResearcherCountList,
  getSurveyCountList,
} from "../../store/Action/SurveyAction";

const About = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { pageListData, loading } = useSelector((state) => state.pageList);

  const { totalSurveyCountListData } = useSelector(
    (state) => state.totalSurveyCountList
  );

  const { totalOrganisationCountListData } = useSelector(
    (state) => state.totalOrganisationCountList
  );
  const { totalResearcherCountListData } = useSelector(
    (state) => state.totalResearcherCountList
  );

  const aboutSlug = location.pathname.split("/")[1];

  React.useEffect(() => {
    dispatch(
      getPageList({
        vSlug: aboutSlug,
        eStatus: "Active",
        eDelete: "No",
      })
    );
  }, [dispatch, aboutSlug]);

  let decodedString = "";

  if (pageListData && pageListData.code === 200 && pageListData.data) {
    const base64Strings = pageListData.data.map((about) => about.tDescription);

    decodedString = atob(base64Strings);
  }

  //FOR TOTAL SURVEY COUNT //
  React.useEffect(() => {
    if (!totalSurveyCountListData) {
      dispatch(getSurveyCountList());
    }
  }, [dispatch, totalSurveyCountListData]);

  // FOR TOTAL ORGANISATION COUNT //
  React.useEffect(() => {
    if (!totalOrganisationCountListData) {
      dispatch(getOrganisationCountList());
    }
  }, [dispatch, totalOrganisationCountListData]);

  // FOR TOTAL RESEARCHER COUNT //
  React.useEffect(() => {
    if (!totalResearcherCountListData) {
      dispatch(getResearcherCountList());
    }
  }, [dispatch, totalResearcherCountListData]);

  return (
    <React.Fragment>
      {loading ? (
        // <div style={{ height: "800px" }}></div>
        <></>
      ) : pageListData && pageListData.code === 200 && pageListData.data ? (
        <div dangerouslySetInnerHTML={{ __html: decodedString }} />
      ) : (
        <div className="col-12">
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px 0",
            }}
          >
            No Data Found
          </p>
        </div>
      )}

      {/* <section className="about-count mt-100">
        <div className="container">
          <div className="row">
            <div className="col-md-4 about-count-view">
              <div className="about-count-box">
                {totalOrganisationCountListData &&
                  totalOrganisationCountListData.code === 200 &&
                  totalOrganisationCountListData.data && (
                    <div className="about-count-content">
                      <h3 className="count">
                        {totalOrganisationCountListData.data.totalOrganization}
                      </h3>
                      <p className="desc">Organisation</p>
                    </div>
                  )}
              </div>
            </div>
            <div className="col-md-4 about-count-view">
              <div className="about-count-box">
                {totalResearcherCountListData &&
                  totalResearcherCountListData.code === 200 &&
                  totalResearcherCountListData.data && (
                    <div className="about-count-content">
                      <h3 className="count">
                        {totalResearcherCountListData.data.totalAcaRes}
                      </h3>
                      <p className="desc"> Academic Researcher</p>
                    </div>
                  )}
              </div>
            </div>
            <div className="col-md-4 about-count-view">
              <div className="about-count-box">
                {totalSurveyCountListData &&
                  totalSurveyCountListData.code === 200 &&
                  totalSurveyCountListData.data && (
                    <div className="about-count-content">
                      <h3 className="count">
                        {" "}
                        {totalSurveyCountListData.data.totalSurvey}
                      </h3>
                      <p className="desc">Survey</p>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </React.Fragment>
  );
};

export default About;
