import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  getCompanyDetailList,
  getSocialList,
} from "../../store/Action/MetaAction";

const Footer = () => {
  const dispatch = useDispatch();

  const { socialListData } = useSelector((state) => state.socialList);

  const { companyDetailListData } = useSelector(
    (state) => state.companyDetailList
  );

  React.useEffect(() => {
    if (!socialListData) {
      dispatch(
        getSocialList({
          eConfig: "Social",
        })
      );
    }
  }, [dispatch, socialListData]);

  React.useEffect(() => {
    if (!companyDetailListData) {
      dispatch(
        getCompanyDetailList({
          eConfig: "Company",
        })
      );
    }
  }, [dispatch, companyDetailListData]);

  const email =
    companyDetailListData &&
    companyDetailListData.code === 200 &&
    companyDetailListData.data &&
    companyDetailListData.data.find((mail) => mail.vName === "COMPANY_EMAIL")
      ?.vValue;

  const number =
    companyDetailListData &&
    companyDetailListData.code === 200 &&
    companyDetailListData.data &&
    companyDetailListData.data.find((mail) => mail.vName === "COMPANY_NUMBER")
      ?.vValue;

  const address =
    companyDetailListData &&
    companyDetailListData.code === 200 &&
    companyDetailListData.data &&
    companyDetailListData.data.find((mail) => mail.vName === "COMPANY_ADDRESS")
      ?.vValue;

  const cleanedAddress = address ? address.replace(/<br\s*\/?>/gi, "") : "";

  const description =
    companyDetailListData &&
    companyDetailListData.code === 200 &&
    companyDetailListData.data &&
    companyDetailListData.data.find((mail) => mail.vName === "COMPANY_DESC")
      ?.vValue;

  const footerdescription =
    companyDetailListData &&
    companyDetailListData.code === 200 &&
    companyDetailListData.data &&
    companyDetailListData.data.find((mail) => mail.vName === "COPYRIGHTED_TEXT")
      ?.vValue;

  return (
    <footer>
      <div className="container">
        <div className="py-60">
          <div className="row g-3">
            <div className="col-sm-12 col-md-12 col-lg-5 col-xl-4">
              <div className="footer-logo">
                <Link to="/">
                  <img
                    src="assets/image/footer-logo.png"
                    alt="logo"
                    width="296"
                    height="68"
                  />
                </Link>
              </div>
              <div className="footer-desc">
                <p>{description}</p>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2">
              <div className="footer-detail">
                <div className="title">Header Links</div>
                <div className="menu-footer">
                  <ul className="footer-menu-list">
                    <li className="">
                      <Link className="text" to="/about-us">
                        About Us
                      </Link>
                    </li>
                    <li className="">
                      <Link className="text" to="/contact-us">
                        Contact Us
                      </Link>
                    </li>
                    <li className="">
                      <Link className="text" to="/privacy-policy">
                        Privacy Policy
                      </Link>
                    </li>
                    {/* <li className="">
                      <Link className="text" to="/terms-and-condition">
                        Terms and Condition
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-5 col-lg-4 col-xl-4">
              <div className="footer-detail">
                <div className="title">Contact Info</div>
                <div className="menu-footer">
                  <ul className="footer-menu-list">
                    {/* <li className="">
                      <a className="footer-contact" href={`tel:${number}`}>
                        <p className="footer-svg">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                          >
                            <path
                              d="M5.05518 4.5H9.05518L11.0552 9.5L8.55518 11C9.62613 13.1715 11.3836 14.929 13.5552 16L15.0552 13.5L20.0552 15.5V19.5C20.0552 20.0304 19.8445 20.5391 19.4694 20.9142C19.0943 21.2893 18.5856 21.5 18.0552 21.5C14.1544 21.263 10.4753 19.6065 7.712 16.8432C4.94868 14.0798 3.29223 10.4007 3.05518 6.5C3.05518 5.96957 3.26589 5.46086 3.64096 5.08579C4.01603 4.71071 4.52474 4.5 5.05518 4.5Z"
                              stroke="white"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </p>
                        <p className="footer-text">{number}</p>
                      </a>
                    </li> */}
                    <li className="">
                      <a className="footer-contact" href={`mailto:${email}`}>
                        <p className="footer-svg">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                          >
                            <path
                              d="M3.05518 7C3.05518 6.46957 3.26589 5.96086 3.64096 5.58579C4.01603 5.21071 4.52474 5 5.05518 5H19.0552C19.5856 5 20.0943 5.21071 20.4694 5.58579C20.8445 5.96086 21.0552 6.46957 21.0552 7M3.05518 7V17C3.05518 17.5304 3.26589 18.0391 3.64096 18.4142C4.01603 18.7893 4.52474 19 5.05518 19H19.0552C19.5856 19 20.0943 18.7893 20.4694 18.4142C20.8445 18.0391 21.0552 17.5304 21.0552 17V7M3.05518 7L12.0552 13L21.0552 7"
                              stroke="white"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </p>
                        <p className="footer-text">{email}</p>
                      </a>
                    </li>
                    <li className="">
                      <p className="footer-contact">
                        <p className="footer-svg">
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                          >
                            <path
                              d="M9.55518 11.5C9.55518 12.2956 9.87125 13.0587 10.4339 13.6213C10.9965 14.1839 11.7595 14.5 12.5552 14.5C13.3508 14.5 14.1139 14.1839 14.6765 13.6213C15.2391 13.0587 15.5552 12.2956 15.5552 11.5C15.5552 10.7044 15.2391 9.94129 14.6765 9.37868C14.1139 8.81607 13.3508 8.5 12.5552 8.5C11.7595 8.5 10.9965 8.81607 10.4339 9.37868C9.87125 9.94129 9.55518 10.7044 9.55518 11.5Z"
                              stroke="white"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M18.2122 17.157L13.9692 21.4C13.5942 21.7746 13.0858 21.985 12.5557 21.985C12.0256 21.985 11.5172 21.7746 11.1422 21.4L6.89818 17.157C5.7794 16.0381 5.01752 14.6127 4.70887 13.0608C4.40022 11.509 4.55867 9.90047 5.16418 8.43868C5.7697 6.9769 6.79508 5.72749 8.11066 4.84846C9.42624 3.96943 10.9729 3.50024 12.5552 3.50024C14.1374 3.50024 15.6841 3.96943 16.9997 4.84846C18.3153 5.72749 19.3407 6.9769 19.9462 8.43868C20.5517 9.90047 20.7101 11.509 20.4015 13.0608C20.0928 14.6127 19.331 16.0381 18.2122 17.157Z"
                              stroke="white"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </p>
                        <p className="footer-text">{cleanedAddress}</p>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-3 col-lg-12 col-xl-2">
              <div className="footer-detail">
                <div className="title">Social Links</div>
                <div className="menu-footer">
                  <ul className="footer-menu-list">
                    {socialListData &&
                      socialListData.code === 200 &&
                      socialListData.data &&
                      socialListData.data.length > 0 && (
                        <>
                          {socialListData &&
                          socialListData.code === 200 &&
                          socialListData.data.find(
                            (s) => s.vName === "SOCIAL_LINKEDIN"
                          )?.vValue ? (
                            <li className="">
                              <Link
                                className="footer-contact"
                                to={
                                  socialListData &&
                                  socialListData.code === 200 &&
                                  socialListData.data.find(
                                    (s) => s.vName === "SOCIAL_LINKEDIN"
                                  )?.vValue
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <p className="footer-svg">
                                  <svg
                                    width="25"
                                    height="24"
                                    viewBox="0 0 25 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M8.52106 11V16M8.52106 8V8.01M12.5211 16V11M16.5211 16V13C16.5211 12.4696 16.3103 11.9609 15.9353 11.5858C15.5602 11.2107 15.0515 11 14.5211 11C13.9906 11 13.4819 11.2107 13.1068 11.5858C12.7318 11.9609 12.5211 12.4696 12.5211 13M4.52106 6C4.52106 5.46957 4.73177 4.96086 5.10684 4.58579C5.48192 4.21071 5.99062 4 6.52106 4H18.5211C19.0515 4 19.5602 4.21071 19.9353 4.58579C20.3103 4.96086 20.5211 5.46957 20.5211 6V18C20.5211 18.5304 20.3103 19.0391 19.9353 19.4142C19.5602 19.7893 19.0515 20 18.5211 20H6.52106C5.99062 20 5.48192 19.7893 5.10684 19.4142C4.73177 19.0391 4.52106 18.5304 4.52106 18V6Z"
                                      stroke="white"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </p>
                                <p className="footer-text">LinkedIn</p>
                              </Link>
                            </li>
                          ) : (
                            <></>
                          )}
                          {socialListData &&
                          socialListData.code === 200 &&
                          socialListData.data.find(
                            (s) => s.vName === "SOCIAL_YOUTUBE"
                          )?.vValue ? (
                            <li className="">
                              <Link
                                className="footer-contact"
                                to={
                                  socialListData &&
                                  socialListData.code === 200 &&
                                  socialListData.data.find(
                                    (s) => s.vName === "SOCIAL_YOUTUBE"
                                  )?.vValue
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <p className="footer-svg">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="24"
                                    viewBox="0 0 25 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M2.02106 8C2.02106 6.93913 2.44248 5.92172 3.19263 5.17157C3.94278 4.42143 4.96019 4 6.02106 4H18.0211C19.0819 4 20.0993 4.42143 20.8495 5.17157C21.5996 5.92172 22.0211 6.93913 22.0211 8V16C22.0211 17.0609 21.5996 18.0783 20.8495 18.8284C20.0993 19.5786 19.0819 20 18.0211 20H6.02106C4.96019 20 3.94278 19.5786 3.19263 18.8284C2.44248 18.0783 2.02106 17.0609 2.02106 16V8Z"
                                      stroke="white"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M10.0211 9L15.0211 12L10.0211 15V9Z"
                                      stroke="white"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </p>
                                <p className="footer-text">YouTube</p>
                              </Link>
                            </li>
                          ) : (
                            <></>
                          )}
                          {/* {socialListData &&
                          socialListData.code === 200 &&
                          socialListData.data.find(
                            (s) => s.vName === "SOCIAL_INSTAGRAM"
                          )?.vValue ? (
                            <li className="">
                              <Link
                                className="footer-contact"
                                to={
                                  socialListData &&
                                  socialListData.code === 200 &&
                                  socialListData.data.find(
                                    (s) => s.vName === "SOCIAL_INSTAGRAM"
                                  )?.vValue
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <p className="footer-svg">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="25"
                                    viewBox="0 0 25 25"
                                    fill="none"
                                  >
                                    <path
                                      d="M17.021 8V8.01M4.521 8.5C4.521 7.43913 4.94242 6.42172 5.69257 5.67157C6.44271 4.92143 7.46013 4.5 8.521 4.5H16.521C17.5819 4.5 18.5993 4.92143 19.3494 5.67157C20.0996 6.42172 20.521 7.43913 20.521 8.5V16.5C20.521 17.5609 20.0996 18.5783 19.3494 19.3284C18.5993 20.0786 17.5819 20.5 16.521 20.5H8.521C7.46013 20.5 6.44271 20.0786 5.69257 19.3284C4.94242 18.5783 4.521 17.5609 4.521 16.5V8.5ZM9.521 12.5C9.521 13.2956 9.83707 14.0587 10.3997 14.6213C10.9623 15.1839 11.7253 15.5 12.521 15.5C13.3166 15.5 14.0797 15.1839 14.6423 14.6213C15.2049 14.0587 15.521 13.2956 15.521 12.5C15.521 11.7044 15.2049 10.9413 14.6423 10.3787C14.0797 9.81607 13.3166 9.5 12.521 9.5C11.7253 9.5 10.9623 9.81607 10.3997 10.3787C9.83707 10.9413 9.521 11.7044 9.521 12.5Z"
                                      stroke="white"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </p>
                                <p className="footer-text">Instagram</p>
                              </Link>
                            </li>
                          ) : (
                            <></>
                          )}
                          {socialListData &&
                          socialListData.code === 200 &&
                          socialListData.data.find(
                            (s) => s.vName === "SOCIAL_FACEBOOK"
                          )?.vValue ? (
                            <li className="">
                              <Link
                                className="footer-contact"
                                to={
                                  socialListData &&
                                  socialListData.code === 200 &&
                                  socialListData.data.find(
                                    (s) => s.vName === "SOCIAL_FACEBOOK"
                                  )?.vValue
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <p className="footer-svg">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="24"
                                    viewBox="0 0 25 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M7.521 10V14H10.521V21H14.521V14H17.521L18.521 10H14.521V8C14.521 7.73478 14.6264 7.48043 14.8139 7.29289C15.0014 7.10536 15.2558 7 15.521 7H18.521V3H15.521C14.1949 3 12.9231 3.52678 11.9855 4.46447C11.0478 5.40215 10.521 6.67392 10.521 8V10H7.521Z"
                                      stroke="white"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </p>
                                <p className="footer-text">Facebook</p>
                              </Link>
                            </li>
                          ) : (
                            <></>
                          )}
                          {socialListData &&
                          socialListData.code === 200 &&
                          socialListData.data.find(
                            (s) => s.vName === "SOCIAL_TWITTER"
                          )?.vValue ? (
                            <li className="">
                              <Link
                                className="footer-contact"
                                to={
                                  socialListData &&
                                  socialListData.code === 200 &&
                                  socialListData.data.find(
                                    (s) => s.vName === "SOCIAL_TWITTER"
                                  )?.vValue
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <p className="footer-svg">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="25"
                                    viewBox="0 0 25 25"
                                    fill="none"
                                  >
                                    <path
                                      d="M4.521 20.1028L11.289 13.3348M13.749 10.8748L20.521 4.10278M4.521 4.10278L16.254 20.1028H20.521L8.788 4.10278H4.521Z"
                                      stroke="white"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </p>
                                <p className="footer-text">Twitter</p>
                              </Link>
                            </li>
                          ) : (
                            <></>
                          )} */}
                        </>
                      )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="copyright">{footerdescription}</p>
    </footer>
  );
};

export default Footer;
