import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import {
  getContactUs,
  ResetContactFormState,
} from "../../store/Action/ContactAction";
import { Toast } from "primereact/toast";
import { getCompanyDetailList } from "../../store/Action/MetaAction";
import { getLabelList } from "../../store/Action/PageAction";
import { useLocation } from "react-router-dom";
import { getSurveyBannerList } from "../../store/Action/BannerAction";

const ContactUs = () => {
  const location = useLocation();
  const captchaRef = useRef(null);
  const dispatch = useDispatch();
  const toast = React.useRef(null);
  const googleSiteKey = "6LeYe08pAAAAAPJnjWVDU47L949QpyoidOH1PkZO";
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [name, setName] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [recaptchaValue, setRecaptchaValue] = React.useState("");

  const [emailError, setEmailError] = React.useState("");
  // const [phoneError, setPhoneError] = React.useState("");
  const [nameError, setNameError] = React.useState("");
  const [messageError, setMessageError] = React.useState("");
  const [recaptchaError, setRecaptchaError] = React.useState("");

  const [loading, setLoading] = React.useState(false);

  const { contactUsData } = useSelector((state) => state.ContactUs);
  const { bannerList } = useSelector((state) => state.banner);

  const { companyDetailListData } = useSelector(
    (state) => state.companyDetailList
  );

  const { labelListData } = useSelector((state) => state.labelList);

  const contactUsPath =
    location && location.pathname && location.pathname.slice(1);

  React.useEffect(() => {
    const executeRecaptcha = async () => {
      try {
        const token = await captchaRef.current.executeAsync();
        setRecaptchaValue(token);
      } catch (error) {
        // console.log(error);
      }
    };

    executeRecaptcha();
  }, []);

  React.useEffect(() => {
    dispatch(
      getSurveyBannerList({
        vSlug: "contact-us-page",
      })
    );
  }, [dispatch]);

  const handleEmailChange = (event) => {
    const emailValue = event.target.value.trim();
    setEmail(emailValue);
    event.target.value.trim() !== "" && setEmailError("");

    // Regular expsion for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailValue.trim() === "" && emailValue.length === 0) {
      setEmailError("Email is required.");
    } else if (!emailRegex.test(emailValue)) {
      setEmailError("Invalid Email format.");
    } else {
      setEmailError("");
    }
  };

  // const handlePhoneChange = (event) => {
  //   const phoneValue = event.target.value.trim();
  //   setPhone(phoneValue);
  //   event.target.value.trim() !== "" && setPhoneError("");

  //   if (phoneValue.trim() === "" && phoneValue.length === 0) {
  //     setPhoneError("Phone number is Required.");
  //   } else if (+phoneValue.length >= 10 && +phoneValue.length <= 12) {
  //     setPhoneError("");
  //   } else {
  //     setPhoneError("Phone number must be between 10 and 12 digits.");
  //   }
  // };

  const handleNameChange = (event) => {
    const enteredName = event.target.value;
    setName(enteredName);
    event.target.value.trim() !== "" && setNameError("");
    if (enteredName.trim() === "") {
      setNameError("Name is required.");
    } else {
      setNameError("");
    }
  };

  const handleMessageChange = (event) => {
    const newMessage = event.target.value;
    setMessage(newMessage);

    if (newMessage.trim() === "" && newMessage.length === 0) {
      setMessageError("Message is Required.");
    } else if (newMessage.length < 120) {
      setMessageError("Message should be at least 120 characters.");
    } else if (newMessage.length > 500) {
      setMessageError("Maximum limit is 500 characters.");
    } else {
      setMessageError("");
    }
  };

  const handleCaptchaChange = (token) => {
    setRecaptchaValue(token);
  };

  const isValidEmail = React.useCallback((email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }, []);

  const ValidateContactForm = React.useCallback(() => {
    let isValid = true;

    // Reset error messages
    setEmailError("");
    // setPhoneError("");
    setNameError("");
    setMessageError("");
    setRecaptchaError("");

    if (email.trim() === "" && email.length === 0) {
      setEmailError("Email is Required.");
      isValid = false;
    } else if (!isValidEmail(email)) {
      setEmailError("Invalid email format.");
      isValid = false;
    }
    // if (phone.trim() === "" && phone.length === 0) {
    //   setPhoneError("Phone Number is Required.");
    //   isValid = false;
    // } else if (!phone.match(/^\+?\d{10,12}$/)) {
    //   setPhoneError("Phone Number should be between 10 and 12 digits.");
    //   isValid = false;
    // }
    if (name.trim() === "" && name.length === 0) {
      setNameError("Name is Required.");
      isValid = false;
    }

    if (message.trim() === "" && message.length === 0) {
      setMessageError("Message is Required.");
      isValid = false;
    } else if (message.length < 120) {
      setMessageError("Message should be at least 120 characters.");
      isValid = false;
    } else if (message.length > 500) {
      setMessageError("Maximum limit is 500 characters.");
      isValid = false;
    } else {
      setMessageError("");
    }

    if (!recaptchaValue) {
      setRecaptchaError("ReCAPTCHA verification failed. Please try again.");
      isValid = false;
    }

    return isValid;
  }, [email, phone, name, message, isValidEmail, recaptchaValue]);

  React.useEffect(() => {
    dispatch(
      getLabelList({
        vPage: contactUsPath,
      })
    );
  }, [dispatch, contactUsPath]);

  const contactTitle =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find((title) => title.vLabel === "LBL_CONTACT_US_TITLE")
      ?.vText;

  const contactSubTitle =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_CONTACT_US_SUB_TITLE"
    )?.vText;

  const contactSuccessToastMessage =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_CONTACT_US_TOAST_MESSAGE"
    )?.vText;

  const contactUsDetail = React.useCallback(
    (e) => {
      e.preventDefault();

      const isValidData = ValidateContactForm();
      if (isValidData) {
        dispatch(
          getContactUs({
            vEmail: email,
            vPhone: phone ? phone : "000-000-0000",
            vName: name,
            tMessage: message,
            vGoogleRecaptchaResponse: recaptchaValue,
          })
        );
        setLoading(true);
      }
    },
    [email, phone, name, message, ValidateContactForm, recaptchaValue, dispatch]
  );

  React.useEffect(() => {
    if (contactUsData && contactUsData.code === 200) {
      dispatch(ResetContactFormState());
      toast.current.show({
        severity: "success",
        summary: contactSuccessToastMessage,
        life: 2000,
        className: "custom-toast",
      });

      setTimeout(() => {
        setLoading(false);
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
        setRecaptchaValue("");
        if (captchaRef.current) {
          captchaRef.current.reset();
          captchaRef.current.executeAsync().then((token) => {
            setRecaptchaValue(token);
          });
        }
      }, 2000);
      window.scrollTo(0, 0);
    } else if (contactUsData && contactUsData.code === 400) {
      setLoading(true);
      dispatch(ResetContactFormState());
      toast.current.show({
        severity: "error",
        summary: contactUsData.message,
        life: 2000,
        className: "custom-toast-error",
      });

      setTimeout(() => {
        setLoading(false);
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
        setRecaptchaValue("");
        if (captchaRef.current) {
          captchaRef.current.reset();
          captchaRef.current.executeAsync().then((token) => {
            setRecaptchaValue(token);
          });
        }
      }, 2000);
    }
  }, [contactUsData, dispatch, contactSuccessToastMessage]);

  React.useEffect(() => {
    if (!companyDetailListData) {
      dispatch(
        getCompanyDetailList({
          eConfig: "Company",
        })
      );
    }
  }, [dispatch, companyDetailListData]);

  const footerEmail =
    companyDetailListData &&
    companyDetailListData.data &&
    companyDetailListData.data.find((mail) => mail.vName === "COMPANY_EMAIL")
      ?.vValue;

  const number =
    companyDetailListData &&
    companyDetailListData.data &&
    companyDetailListData.data.find((mail) => mail.vName === "COMPANY_NUMBER")
      ?.vValue;

  const address =
    companyDetailListData &&
    companyDetailListData.data &&
    companyDetailListData.data.find((mail) => mail.vName === "COMPANY_ADDRESS")
      ?.vValue;

  const cleanedAddress = address ? address.replace(/<br\s*\/?>/gi, "") : "";

  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = React.useRef(null);

  const handlePlay = () => {
    setIsPlaying(true);
    videoRef.current.play();
  };

  return (
    <>
      <Toast ref={toast} />
      {bannerList &&
      bannerList.code === 200 &&
      bannerList.data &&
      bannerList.data.length > 0 ? (
        bannerList.data.map((banner, bannerIndex) => (
          <>
            <section
              className="about-banner mx-45"
              style={{
                backgroundImage:
                  String(banner?.vImage.split("/").pop()) === "null"
                    ? "none"
                    : `url(${banner?.vImage})`,
                backgroundColor:
                  String(banner?.vImage.split("/").pop()) === "null"
                    ? banner?.vColor
                    : "transparent",
              }}
            >
              <div className="container-fluid">
                <div className="row">
                  <div className="col-xl-7 col-lg-10 col-md-12 m-auto">
                    <div className="banner-content">
                      <h1 className="banner-title">{banner.vHeading}</h1>
                      <p className="banner-desc">{banner.vSubHeading}</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        ))
      ) : (
        <></>
      )}

      <section className="conatct-us py-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="conatct-us-left-view">
                <div className="contact-box">
                  <div className="contact-icon">
                    <img
                      src="assets/image/contact-icon-1.png"
                      alt="contacticonimage"
                      width="50"
                      height="50"
                    />
                  </div>
                  <div className="contact-box-content">
                    <a className="title" href={`mailto:${email}`}>
                      {footerEmail}
                    </a>
                  </div>
                </div>
                {/* <div className="contact-box">
                  <div className="contact-icon">
                    <img
                      src="assets/image/contact-icon-2.png"
                      alt="contacticonimage"
                      width="50"
                      height="50"
                    />
                  </div>
                  <div className="contact-box-content">
                    <a className="title" href={`tel:${number}`}>
                      {number}
                    </a>
                  </div>
                </div> */}
                <div className="contact-box">
                  <div className="contact-icon">
                    <img
                      src="assets/image/contact-icon-3.png"
                      alt="contacticonimage"
                      width="50"
                      height="50"
                    />
                  </div>
                  <div className="contact-box-content">
                    <p className="title">{cleanedAddress}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
                  <div className="contact-video position-relative">
                      {!isPlaying && (
                        <div
                          className="play-icon"
                          onClick={handlePlay}
                        >
                        <img src="assets/image/play-button.png" alt="play button" height={'40'} width={'40'}/>
                        </div>
                      )}
                      <video
                        ref={videoRef}
                        width="100%"
                        controls
                        poster="assets/image/what-we-do.jpg"
                        onPlay={() => setIsPlaying(true)}
                      >
                        <source src="assets/video/NOVCSEFINAL.mp4" type="video/mp4" />
                      </video>
                    </div>
              {/* <form className="contact-us-form" onSubmit={contactUsDetail}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="input-box">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        Email address
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Email Address"
                        value={email}
                        onChange={handleEmailChange}
                      />
                      {emailError && (
                        <div
                          className="error-msg"
                          style={{
                            color: "red",
                            marginLeft: "5px",
                            fontSize: "14px",
                          }}
                        >
                          {" "}
                          {emailError}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-md-12">
                    <div className="input-box">
                      <label
                        htmlFor="exampleInputnumber"
                        className="form-label"
                      >
                        Phone
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Your Phone"
                        value={phone}
                        onChange={handlePhoneChange}
                        maxLength={12}
                        onKeyPress={(e) => {
                          const validCharacters = /^[0-9+]+$/;
                          if (!validCharacters.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                      {phoneError && (
                        <div
                          className="error-msg"
                          style={{
                            color: "red",
                            marginLeft: "5px",
                            fontSize: "14px",
                          }}
                        >
                          {" "}
                          {phoneError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-box">
                      <label htmlFor="exampleInputname" className="form-label">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Your Name"
                        value={name}
                        onChange={handleNameChange}
                      />
                      {nameError && (
                        <div
                          className="error-msg"
                          style={{
                            color: "red",
                            marginLeft: "5px",
                            fontSize: "14px",
                          }}
                        >
                          {" "}
                          {nameError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-box">
                      <label htmlFor="exampleInputname" className="form-label">
                        Message
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        rows={4}
                        cols={50}
                        placeholder="Type Something Here..."
                        value={message}
                        onChange={handleMessageChange}
                      ></textarea>
                      {messageError && (
                        <div
                          className="error-msg"
                          style={{
                            color: "red",
                            marginLeft: "5px",
                            fontSize: "14px",
                          }}
                        >
                          {" "}
                          {messageError}
                        </div>
                      )}
                    </div>
                  </div>
                  <ReCAPTCHA
                    ref={captchaRef}
                    sitekey={googleSiteKey}
                    size="invisible"
                    onChange={handleCaptchaChange}
                  />
                  {recaptchaError && (
                    <div
                      className="error-msg"
                      style={{
                        color: "red",
                        fontSize: "14px",
                        marginLeft: "5px",
                        marginBottom: "10px",
                      }}
                    >
                      {recaptchaError}
                    </div>
                  )}
                  <div className="col-md-12">
                    <div className="login-btn-view">
                      {loading ? (
                        <>
                          {" "}
                          <button className="login-btn" type="button" disabled>
                            <span
                              className="spinner-border spinner-border-sm me-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Loading...
                          </button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <button type="submit" className="login-btn">
                            Submit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </form> */}
            </div>
          </div>
        </div>
      </section>
      {/* 
      <section className="contact-maps py-100">
        <div className="container">
          <div className="location">
            <iframe
              title="googleMap"
              src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3443696.1084610904!2d-89.32092830505084!3d32.55325942848925!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s957%20Hester%20Row%2C%20Cypress%2C%20Alabama%20-%2083863%2C%20Iceland!5e0!3m2!1sen!2sin!4v1721287108570!5m2!1sen!2sin"
              width="100%"
              height="390px"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default ContactUs;
